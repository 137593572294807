import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e936ac0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "auth-provider-sign-in" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", { src: _ctx.logo }, null, 8, _hoisted_2),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ])
  ]))
}