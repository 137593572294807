
import { defineComponent, PropType } from "vue";
import ClickableMixin from "@/components/mixins/Clickable";

export default defineComponent({
  name: "AuthProviderSignInButton",
  mixins: [ClickableMixin],
  props: {
    logo: {
      type: String,
      required: true
    }
  },
});
